
import useTenant from "@/composables/useTenant";
import { onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { object, string } from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "Tenancy",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { tenant, getTenant, fetchTenant, removeTenant } = useTenant();
    //Create form validation object
    const loading = ref<boolean>(false);
    const switchToTenant = ref<boolean>(false);
    const tenantName = object().shape({
      // Use validator later
      tenancyNameInput: string().default(() => "Nigeria"), //.required("Tenancy Name is required"),
    });

    onMounted(() => {
      fetchTenant();

      if (tenant.value?.tenancyName) {
        switchToTenant.value = true;
      }
    });

    const switchTenancy = async (values) => {
      loading.value = true;
      const { tenancyNameInput } = tenantName.cast(values);

      if (switchToTenant.value) {
        const status = await getTenant({
          tenancyName: tenancyNameInput,
        } as unknown as any);
        if (status) {
          window.location.reload();
        } else {
          Swal.fire({
            text: "Invalid tenancy",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      } else {
        removeTenant();
        window.location.reload();
      }
      loading.value = false;
      // window.location.reload();
    };

    return {
      tenant,
      switchToTenant,
      switchTenancy,
      tenantName,
      loading,
    };
  },
};
